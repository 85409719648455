import React from 'react';
import { useField } from 'formik';
import { cn } from '@/util/styleUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as SolidStar } from '@fortawesome/free-solid-svg-icons/faStar';
import { faStar as OutlineStar } from '@fortawesome/free-regular-svg-icons/faStar';
import FieldError from '@/components/forms/controls/FieldError';

export type RatingFieldProps = {
    name: string;
    className?: string;
    onChange?: (rating: number) => void;
    hideDescription?: boolean;
};

function StarButtonField({
    value,
    name,
    onChange,
}: {
    value: number;
    name: string;
    onChange?: (rating: number) => void;
}) {
    const [, meta, helpers] = useField<number>(name);
    const current = meta.value;
    const selected = current >= value;
    return (
        <button
            className={'peer rounded p-1 px-1.5 hover:bg-accent peer-hover:text-yellow-500'}
            onClick={async () => {
                await helpers.setValue(value);
                onChange?.(value);
            }}
            type={'button'}
        >
            <FontAwesomeIcon
                icon={selected ? SolidStar : OutlineStar}
                size={'xl'}
                className={cn({ 'text-yellow-500': selected, 'text-muted-foreground/50': !selected })}
            />
        </button>
    );
}

export function RatingField({ name, className, onChange, hideDescription = false }: RatingFieldProps) {
    const [, meta] = useField<number>(name);
    const value = meta.value;

    return (
        <div className={'inline-block space-y-2'}>
            {/*This list needs to be reversed due to peer selection css rules*/}
            <div className={cn('flex flex-row-reverse items-center', className)}>
                <StarButtonField value={5} name={name} onChange={onChange} />
                <StarButtonField value={4} name={name} onChange={onChange} />
                <StarButtonField value={3} name={name} onChange={onChange} />
                <StarButtonField value={2} name={name} onChange={onChange} />
                <StarButtonField value={1} name={name} onChange={onChange} />
            </div>
            {!hideDescription && (
                <div className={'text-center text-sm text-muted-foreground'}>
                    {value === 0 && !meta.error && !meta.touched && <span>Please choose a rating</span>}
                    {value === 1 && <span>Very disappointing. What went wrong?</span>}
                    {value === 2 && <span>Pretty bad. What went wrong?</span>}
                    {value === 3 && <span>Just average. What went wrong?</span>}
                    {value === 4 && <span>Pretty good, but what could be better?</span>}
                    {value === 5 && <span>Excellent rating! What did you enjoy?</span>}
                </div>
            )}
            <FieldError name={name} className={'text-center'} />
        </div>
    );
}

RatingField.displayName = 'RatingField';
