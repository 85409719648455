export enum TagCategoryName {
    allergen = 'Allergen',
    spice_level = 'Spice Level',
    protein = 'Protein',
    cuisine = 'Cuisine',
    promotional = 'Promotional',
}

export default interface TagCategory {
    id: number;
    display_name: string;
}
