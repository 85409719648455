import useSWR from 'swr';
import { Paginated } from '@/hooks/useApi';
import Tag from '@/models/Tag';
import Endpoint from '@/config/Endpoint';
import TagCategory from '@/models/TagCategory';
import { useMemo } from 'react';

const NO_CATEGORY_ID = -1;

export const tagComparator = (t1: Tag, t2: Tag) => (t1.display_name ?? '').localeCompare(t2.display_name ?? '');
export const categoryComparator = (c1: TagCategory, c2: TagCategory) =>
    (c1.display_name ?? '').localeCompare(c2.display_name ?? '');
export const tagCategoryComparator = (t1: Tag, t2: Tag) => {
    if (t1.category?.display_name !== t2.category?.display_name) {
        return (t1.category?.display_name ?? '').localeCompare(t2.category?.display_name ?? '');
    }
    return tagComparator(t1, t2);
};

const useTags = () => {
    const { data: tagData } = useSWR<Paginated<Tag>>(Endpoint.tags.base);
    const { data: categoryData } = useSWR<Paginated<TagCategory>>(Endpoint.tagCategories.base);

    return useMemo(() => {
        const tagsByCategory = (tagData?.data ?? []).reduce<Record<number, Tag[]>>((categoryMap, tag) => {
            const categoryList: Tag[] = categoryMap[tag.tag_category_id ?? NO_CATEGORY_ID] ?? [];
            categoryMap[tag.tag_category_id ?? NO_CATEGORY_ID] = [...categoryList, tag];
            return categoryMap;
        }, {});

        Object.values(tagsByCategory).forEach((tags) => {
            tags.sort(tagComparator);
        });

        const sortedCategories: TagCategory[] = (categoryData?.data ?? []).sort(categoryComparator);

        const sortedTags = [...(tagData?.data ?? [])].sort(tagComparator);
        const sortedTagsByCategory = [...(tagData?.data ?? [])].sort(tagCategoryComparator);
        return { tagsByCategory, sortedCategories, sortedTags, sortedTagsByCategory };
    }, [tagData, categoryData]);
};

export default useTags;
