import Endpoint from '@/config/Endpoint';
import { Subscriber } from '@/models/Subscriber';
import useApi from '@/hooks/useApi';
import Logger from '@/util/Logger';
import { useAuthContext } from '@/components/contexts/AuthContext';

const logger = Logger.make('useAuth');
const useAuth = () => {
    const {
        subscriber,
        mutateSubscriber,
        isLoggedIn,
        isLoading,
        isAdmin,
        isSuperAdmin,
        subscriberError,
        subscriberLoading,
    } = useAuthContext();
    const api = useApi();

    const updateSubscriber = async (changes: Partial<Subscriber>) => {
        logger.debug('Updating subscriber with values', changes);
        return await mutateSubscriber(async (current) => {
            try {
                // TODO: make this only update the current subscriber, needs new endpoint for PATCH /subscribers/:id
                return await api.patch<Subscriber>(Endpoint.subscribers, { ...current, ...changes });
            } catch (error) {
                return current;
            }
        });
    };

    return {
        subscriber,
        subscriberId: subscriber?.id,
        isAdmin,
        isSuperAdmin,
        loggedIn: isLoggedIn,
        isLoading: isLoading || subscriberLoading,
        mutateSubscriber,
        subscriberLoading: subscriberLoading,
        subscriberError,
        updateSubscriber,
    };
};

export default useAuth;
