import React, { HTMLProps, ReactNode } from 'react';
import FormikInput from '@/components/forms/controls/FormikInput';
import FormField from '@/components/forms/controls/FormField';
import FieldError from '@/components/forms/controls/FieldError';
import FieldLabel from '@/components/forms/controls/FieldLabel';

type Props = {
    name: string;
    label?: ReactNode | null;
    description?: ReactNode;
    useError?: boolean;
} & Omit<HTMLProps<HTMLInputElement>, 'label'>;
const TextareaField = ({ name, label, type = 'text', description, useError = true, ...props }: Props) => {
    return (
        <FormField>
            <div className={'mb-1'}>
                {label !== null && <FieldLabel className={'label'}>{label ?? name}</FieldLabel>}
                {description && <div className="font-sm">{description}</div>}
            </div>

            <FormikInput as="textarea" type={type} name={name} placeholder="" {...props} />
            {useError && <FieldError className={'mt-1'} name={name} />}
        </FormField>
    );
};

export default TextareaField;
