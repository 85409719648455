import React from 'react';
import { RecipeWithPricing } from '@/models/Recipe';
import { RecipeTabContentTitle } from '@/app/menus/[menuId]/components/recipe-card/tabs/RecipeTabContentTitle';

export type RecipeOverviewContentProps = { recipe: RecipeWithPricing };

export function RecipeOverviewContent({ recipe }: RecipeOverviewContentProps) {
    return (
        <div>
            <RecipeTabContentTitle>Overview</RecipeTabContentTitle>
            <div className={'max-h-52 overflow-auto pb-6'}>{recipe.description}</div>
        </div>
    );
}

RecipeOverviewContent.displayName = 'RecipeOverviewContent';
