import React from 'react';
import { Recipe } from '@/models/Recipe';
import { isBlank } from '@/util/TypeUtil';
import { Utensils } from 'lucide-react';
import { cn } from '@/util/styleUtils';
import { getSizeImageUrl } from '@/util/StringUtil';

export type RecipeImageProps = {
    recipe: Recipe;
    useFallback?: boolean;
    className?: string;
    imageWidth?: number;
    grayscale?: boolean;
};

export function RecipeImage({
    recipe,
    useFallback = false,
    className,
    imageWidth = 750,
    grayscale = false,
}: RecipeImageProps) {
    const imageUrl = getSizeImageUrl(recipe.before_image_url || recipe.after_image_url, imageWidth);
    if (!imageUrl || isBlank(imageUrl)) {
        if (!useFallback) {
            return null;
        }
        return (
            <div className={cn('flex items-center justify-center bg-orange/30 p-8 text-orange', className)}>
                <Utensils className={'h-12 w-12'} />
            </div>
        );
    }

    return (
        <div className={cn(className)}>
            <img
                src={imageUrl}
                alt={`Photo of ${recipe.name}`}
                className={cn('h-full w-full object-cover object-center', {
                    'grayscale transition-all duration-300 group-hover:grayscale-0': grayscale,
                })}
            />
        </div>
    );
}

RecipeImage.displayName = 'RecipeImage';
