import React from 'react';
import { isBlank } from '@/util/TypeUtil';
import { cn } from '@/util/styleUtils';

export type ProseContentProps = { html: string | null | undefined; className?: string };

export const baseProseClassNames =
    'prose prose-headings:my-0 prose-headings:font-bold prose-p:my-0 prose-p:first:mt-0 prose-ul:my-0 prose-ol:py-0 prose-ol:my-0 relative' +
    ' marker:text-foreground prose-li:my-4 prose-li:py-0 prose-li:pl-0 max-w-none';

export function ProseContent({ html, className }: ProseContentProps) {
    if (!html || isBlank(html)) {
        return null;
    }
    return (
        <div
            dangerouslySetInnerHTML={{ __html: html }}
            className={cn(baseProseClassNames, 'leading-normal', className)}
        ></div>
    );
}

ProseContent.displayName = 'ProseContent';
