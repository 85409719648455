import useSWR from 'swr';
import { RecipeReview } from '@/models/RecipeReview';
import Endpoint from '@/config/Endpoint';
import useAuth from '@/hooks/useAuth';

export function useSubscriberReviews(subscriber_id: number | null | undefined) {
    const { data, error, isLoading, ...swrStuff } = useSWR<RecipeReview[]>(() =>
        !!subscriber_id ? Endpoint.subscriber(subscriber_id).reviews : null
    );

    const reviewsByRecipeId = new Map<number, RecipeReview>();
    data?.forEach((review) => {
        reviewsByRecipeId.set(review.recipe_id, review);
    });

    return { data, error, isLoading, ...swrStuff, reviewsByRecipeId };
}

export function useSubscriberReview(subscriber_id: number | null | undefined, recipe_id: number) {
    const { reviewsByRecipeId } = useSubscriberReviews(subscriber_id);
    return { review: reviewsByRecipeId.get(recipe_id) ?? null };
}

export function useCurrentUserReviews() {
    const { subscriberId } = useAuth();
    return useSubscriberReviews(subscriberId);
}

export function useCurrentUserReview(recipe_id: number) {
    const { subscriberId } = useAuth();

    return useSubscriberReview(subscriberId, recipe_id);
}
