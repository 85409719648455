import React from 'react';
import { RecipeTabContentTitle } from '@/app/menus/[menuId]/components/recipe-card/tabs/RecipeTabContentTitle';
import { RecipeWithPricing } from '@/models/Recipe';
import { NutritionalFactsTable } from '@/components/NutritionalFactsTable';

export type NutritionalContentsProps = { recipe: RecipeWithPricing };

export function NutritionalContents({ recipe }: NutritionalContentsProps) {
    return (
        <div>
            <RecipeTabContentTitle>Nutrition Facts</RecipeTabContentTitle>
            <div className={'max-w-md'}>
                {recipe.nutritional_info ? (
                    <NutritionalFactsTable facts={recipe.nutritional_info} />
                ) : (
                    <div>Nutritional information is not available at this time</div>
                )}
            </div>
        </div>
    );
}

NutritionalContents.displayName = 'NutritionalContents';
