import { Recipe } from '@/models/Recipe';
import { tagHasAnyCategory } from '@/models/Tag';
import { TagCategoryName } from '@/models/TagCategory';
import RecipeTag from '@/components/RecipeTag';
import TagView from '@/components/TagView';
import { cn } from '@/util/styleUtils';
import { ReactNode, useMemo } from 'react';
import { tagComparator } from '@/hooks/useTags';

type Props = {
    recipe: Recipe;
    hideGlutenFree?: boolean;
    hideVegetarian?: boolean;
    categoryFilters?: TagCategoryName[];
    className?: string;
    before?: ReactNode;
    after?: ReactNode;
    scroll?: boolean;
};
const RecipeTagList = ({
    recipe,
    categoryFilters,
    className,
    hideGlutenFree,
    hideVegetarian,
    before,
    after,
    scroll = false,
}: Props) => {
    const { filteredTags } = useMemo(() => {
        let filteredTags = recipe.tags ?? [];
        if (categoryFilters && categoryFilters.length > 0) {
            filteredTags = filteredTags.filter((t) => !tagHasAnyCategory(t, categoryFilters));
        }
        filteredTags.sort(tagComparator);
        return { filteredTags };
    }, [recipe.tags]);

    const showVegetarian = !hideVegetarian && recipe.vegetarian;
    const showGlutenFree = !hideGlutenFree && recipe.gluten_free;
    const hasTags = filteredTags.length > 0 || showGlutenFree || showVegetarian;

    if (!hasTags && !before && !after) {
        return null;
    }

    return (
        <div className={cn(`flex`, { 'flex-wrap': !scroll, 'overflow-auto': scroll }, className)}>
            {before}
            {showGlutenFree && <TagView title={'Gluten Free'} color={'gray'} />}
            {showVegetarian && <TagView title={'Vegetarian'} color={'gray'} />}
            {filteredTags.map((tag) => (
                <RecipeTag tag={tag} key={tag.id} />
            ))}
            {after}
        </div>
    );
};

export default RecipeTagList;
