import React from 'react';
import { faStar as SolidStar } from '@fortawesome/free-solid-svg-icons/faStar';
import { faStarHalf as HalfSolidStar } from '@fortawesome/free-solid-svg-icons/faStarHalf';
import { faStar as OutlineStar } from '@fortawesome/free-regular-svg-icons/faStar';
import { cn } from '@/util/styleUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';

export type StarRatingDisplayProps = {
    rating: number;
    size?: SizeProp;
    containerClassName?: string;
    selectedClassName?: string;
    unselectedClassName?: string;
    onSelected?: (value: number) => void;
};

const star_values = [1, 2, 3, 4, 5];

export function StarRatingDisplay({
    rating,
    size = 'lg',
    selectedClassName = 'text-yellow-500',
    unselectedClassName = 'text-muted-foreground/50',
    containerClassName,
    onSelected,
}: StarRatingDisplayProps) {
    return (
        <div className={cn('inline-grid grid-cols-5 gap-1', containerClassName)}>
            {star_values.map((value) => {
                const selected = value <= Math.round(rating);
                const isHalf = value > Math.floor(rating) && value <= Math.ceil(rating);
                const starClasses = cn({
                    [cn('text-yellow-500 fill-current', selectedClassName)]: selected,
                    [cn('text-muted-foreground/50', unselectedClassName)]: !selected,
                });
                const $star = (
                    <FontAwesomeIcon
                        key={value}
                        icon={selected ? SolidStar : OutlineStar}
                        size={size}
                        className={starClasses}
                    />
                );
                const $halfStar = (
                    <div>
                        <FontAwesomeIcon
                            key={value}
                            icon={OutlineStar}
                            size={size}
                            className={cn(starClasses, 'absolute', unselectedClassName)}
                        />
                        <FontAwesomeIcon
                            key={value}
                            icon={HalfSolidStar}
                            size={size}
                            className={cn(starClasses, selectedClassName, 'absolute')}
                        />
                    </div>
                );
                return onSelected ? (
                    <div
                        onClick={() => onSelected(value)}
                        key={value}
                        className={cn(
                            'flex cursor-pointer items-center justify-center rounded-full  p-2 hover:bg-accent/80 active:bg-accent',
                            starClasses
                        )}
                    >
                        {$star}
                    </div>
                ) : isHalf ? (
                    $halfStar
                ) : (
                    $star
                );
            })}
        </div>
    );
}

StarRatingDisplay.displayName = 'StarRatingDisplay';
