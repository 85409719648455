import useSWR from 'swr';
import { ObjectionPage } from '@/hooks/useApi';
import { RecipeReview } from '@/models/RecipeReview';
import Endpoint from '@/config/Endpoint';

export function useRecipeReviews(recipe_id: number) {
    const { data, ...rest } = useSWR<ObjectionPage<RecipeReview>>(Endpoint.recipe(recipe_id).publicReviews);
    const total_score =
        data?.results?.reduce((rating, review) => {
            return rating + review.rating;
        }, 0) ?? 0;
    let avg_score: number | undefined;
    if (data && data.results.length > 0) {
        avg_score = total_score / data.results.length;
    }
    return { ...rest, data, avg_score };
}
