import React, { useState } from 'react';
import { RecipeTabContentTitle } from '@/app/menus/[menuId]/components/recipe-card/tabs/RecipeTabContentTitle';
import { RecipeWithPricing } from '@/models/Recipe';
import { RecipeReview } from '@/models/RecipeReview';
import LoadingMessage from '@/components/LoadingMessage';
import Alert from '@/components/Alert';
import { CreateRecipeReviewForm } from '@/app/menus/[menuId]/components/recipe-card/tabs/content/CreateRecipeReviewForm';
import { Button } from '@/components/ui/button';
import { ReviewItem } from '@/app/menus/[menuId]/components/recipe-card/tabs/content/ReviewItem';
import { useRecipeReviews } from '@/hooks/useRecipeReviews';
import { StarRatingDisplay } from '@/components/StarRatingDisplay';
import { pluralize } from '@/util/StringUtil';
import { Separator } from '@/components/ui/separator';

export type RecipeReviewContentsProps = { recipe: RecipeWithPricing; review?: RecipeReview | null };

export function RecipeReviewContents({ recipe, review }: RecipeReviewContentsProps) {
    // const { data, isLoading, error } = useSWR<ObjectionPage<RecipeReview>>(Endpoint.recipe(recipe.id).reviews);
    const { data, isLoading, error, avg_score } = useRecipeReviews(recipe.id);
    const [createOpen, setCreateOpen] = useState(false);
    return (
        <div className={'space-y-6'}>
            {!createOpen && (
                <div className={'flex items-center space-x-2'}>
                    {avg_score ? (
                        <div className={'w-full space-y-6'}>
                            <div className={'flex items-center space-x-2 font-bold'}>
                                <StarRatingDisplay rating={avg_score} />
                                <span>{avg_score.toFixed(1)}</span>{' '}
                                <span>
                                    {data?.total ? `(${pluralize(data.total, 'reviews', 'review')})` : undefined}
                                </span>
                            </div>
                            <Separator />
                        </div>
                    ) : (
                        <RecipeTabContentTitle>
                            Reviews {(data?.total ?? 0) > 0 && <span>({data?.total})</span>}
                        </RecipeTabContentTitle>
                    )}
                </div>
            )}
            {!createOpen && !isLoading && data && data?.results.length > 0 && !review && (
                <div>
                    <Button variant={'outlined'} size={'sm'} onClick={() => setCreateOpen(true)}>
                        Add your review
                    </Button>
                </div>
            )}

            {isLoading && <LoadingMessage message={'Loading reviews...'} />}
            {createOpen && (
                <div className={'space-y-4'}>
                    <div className={'text-lg font-bold'}>{review ? 'Edit' : 'Add'} your review</div>
                    <div className={'rounded-lg border p-4'}>
                        <CreateRecipeReviewForm
                            recipe={recipe}
                            afterSave={() => setCreateOpen(false)}
                            edit={review ?? undefined}
                        >
                            <CreateRecipeReviewForm.Fields />
                            <CreateRecipeReviewForm.Actions onCancel={() => setCreateOpen(false)} />
                        </CreateRecipeReviewForm>
                    </div>
                </div>
            )}
            {!isLoading && data && data?.results.length === 0 && !createOpen && (
                <div className={'space-y-4 rounded-lg border-2 border-dashed p-8 text-center text-muted-foreground'}>
                    <div className={'font-bold'}>No reviews have been added</div>
                    {!review && (
                        <Button size={'sm'} onClick={() => setCreateOpen(true)} variant={'outlined'}>
                            Add your review
                        </Button>
                    )}
                </div>
            )}
            {!isLoading && !data && error && (
                <Alert level={'error'}>Unable to load reviews. Please try again later</Alert>
            )}
            {!createOpen && (data?.results ?? []).length > 0 && (
                <div className={'max-h-[500px] space-y-2 overflow-auto pb-8'}>
                    {data?.results?.map((r) => (
                        <ReviewItem
                            review={r}
                            key={r.id}
                            selected={r.id === review?.id}
                            onEdit={() => setCreateOpen(true)}
                        />
                    ))}
                </div>
            )}
        </div>
    );
}

RecipeReviewContents.displayName = 'RecipeReviewContents';
