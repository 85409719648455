'use client';
import config, { GOOGLE_ANALYTICS } from '@/config/Config';
import * as Sentry from '@sentry/nextjs';
import Logger from '@/util/Logger';

const logger = Logger.make('Analytics');

type GTagEvent = {
    action: string;
    category?: string;
    label?: string;
    value?: number;
    [key: string]: string | number | null | undefined;
};

type AnalyticsUser = { id?: string | number; email?: string; name?: string };

export function ensureGtag() {
    'use client';
    if (window === undefined) {
        return;
    }
    if (window.gtag === undefined) {
        logger.warn('No gtag found on the page, adding temp object');
        window.dataLayer = window.dataLayer || [];
        window.gtag = function () {
            //eslint-disable-next-line
            window.dataLayer?.push(arguments);
        };
    }
}

export const identifyUser = (user: AnalyticsUser | null) => {
    ensureGtag();
    if (user) {
        logger.log(`Logged in as ${user.name ?? ''} (${user.email})`);
    }
    if (config.IS_DEV) {
        return;
    }

    try {
        if (user && user.id) {
            const { id, email } = user;

            gtag('config', GOOGLE_ANALYTICS.MEASUREMENT_ID, {
                user_id: `${id}`,
                debug_mode: GOOGLE_ANALYTICS.DEBUG_MODE,
                traffic_type: GOOGLE_ANALYTICS.TRAFFIC_TYPE,
            });
            gtag('set', { user_id: id ? null : `${id}` });
            Sentry.setUser({ email, id });
        } else {
            gtag('set', { user_id: null });
            Sentry.configureScope((scope) => {
                scope.setUser(null);
            });
        }
    } catch (error) {
        logger.error(error);
    }
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string) => {
    ensureGtag();
    gtag('event', 'page_view', {
        page_path: url,
        debug_mode: GOOGLE_ANALYTICS.DEBUG_MODE,
        traffic_type: GOOGLE_ANALYTICS.TRAFFIC_TYPE,
    });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const configureGtag = (url: string) => {
    ensureGtag();
    gtag('config', GOOGLE_ANALYTICS.MEASUREMENT_ID, {
        page_path: url,
        debug_mode: GOOGLE_ANALYTICS.DEBUG_MODE,
        traffic_type: GOOGLE_ANALYTICS.TRAFFIC_TYPE,
    });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }: GTagEvent) => {
    ensureGtag();
    gtag('event', action, {
        event_category: category,
        event_label: label,
        value: value,
        debug_mode: GOOGLE_ANALYTICS.DEBUG_MODE,
        traffic_type: GOOGLE_ANALYTICS.TRAFFIC_TYPE,
    });
};
