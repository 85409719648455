import React from 'react';
import { RecipeTabContentTitle } from '@/app/menus/[menuId]/components/recipe-card/tabs/RecipeTabContentTitle';
import { Ingredient, RecipeWithPricing } from '@/models/Recipe';
import { cn } from '@/util/styleUtils';

export type IngredientsContentProps = { recipe: RecipeWithPricing };

export function IngredientsContent({ recipe }: IngredientsContentProps) {
    const ingredients: Ingredient[] | null = recipe.ingredients;
    return (
        <div className={'space-y-4 @container'}>
            <RecipeTabContentTitle>Ingredients</RecipeTabContentTitle>

            {ingredients && ingredients.length > 0 ? (
                <div className={'max-h-52 overflow-auto'}>
                    <ul
                        className={cn('list-disc columns-1 gap-x-8 pl-5', {
                            '@lg:columns-2 @xl:columns-3': ingredients.length > 6,
                        })}
                    >
                        {ingredients?.map((ingredient, i) => <li key={`${ingredient}_${i}`}>{ingredient}</li>)}
                    </ul>
                </div>
            ) : (
                <div
                    className={
                        'flex items-center justify-center rounded-lg border-2 border-dashed p-6 text-muted-foreground'
                    }
                >
                    <div>Ingredients list is not yet available</div>
                </div>
            )}
        </div>
    );
}

IngredientsContent.displayName = 'IngredientsContent';
