import React from 'react';
import { cn } from '@/util/styleUtils';
import { Separator } from '@/components/ui/separator';
import { isDefined } from '@/util/TypeGuards';
import { pluralize } from '@/util/StringUtil';
import { GalleyRecipeFragment } from '@/models/galley-types';
import Fraction from 'fraction.js';

export type NutritionalFactsTableProps = { facts: NutritionalFacts };

export type NutritionalItem = { name: string; amount: number; units: string; pct_daily_value: number };
export type NutritionalItemWithItems = NutritionalItem & { items?: NutritionalItem[] };
export type NutritionalHeadingItem = NutritionalItem & { items?: NutritionalItemWithItems[] };
export type NutritionalFacts = {
    breakdown: NutritionalHeadingItem[];
    calories_per_serving: number | null;
    serving_size?: string;
    servings_per_container?: number | null;
    nutritionals_quantity?: number | null;
};

export function galleyRecipeToFacts(recipe: GalleyRecipeFragment): NutritionalFacts | null {
    const data = recipe.reconciledNutritionals;
    if (!data) {
        return null;
    }

    const breakdownItems: NutritionalHeadingItem[] = [
        {
            name: 'Total fat',
            amount: data.totalFatG ?? 0,
            units: 'g',
            pct_daily_value: data.totalFatPercentDRV ?? 0,
            items: [
                {
                    name: 'Saturated Fat',
                    amount: data.saturatedFatG ?? 0,
                    units: 'g',
                    pct_daily_value: data.saturatedFatPercentDRV ?? 0,
                },
                {
                    name: 'Trans Fat',
                    amount: data.transFatG ?? 0,
                    units: 'g',
                    pct_daily_value: data.transFatPercentDRV ?? 0,
                },
            ],
        },
    ];

    if (isDefined(data.cholesterolMg) && isDefined(data.cholesterolPercentDRV)) {
        breakdownItems.push({
            name: 'Cholesterol',
            amount: data.cholesterolMg,
            units: 'mg',
            pct_daily_value: data.cholesterolPercentDRV,
        });
    }

    if (isDefined(data.sodiumMg) && isDefined(data.sodiumPercentDRV)) {
        breakdownItems.push({
            name: 'Sodium',
            amount: data.sodiumMg,
            units: 'mg',
            pct_daily_value: data.sodiumPercentDRV,
        });
    }

    if (isDefined(data.carbsG) && isDefined(data.carbsPercentDRV)) {
        const carbItems: NutritionalItemWithItems[] = [];

        if (isDefined(data.fiberG) && isDefined(data.fiberPercentDRV)) {
            carbItems.push({
                name: 'Dietary Fiber',
                amount: data.fiberG,
                units: 'g',
                pct_daily_value: data.fiberPercentDRV,
            });
        }
        if (isDefined(data.sugarG)) {
            carbItems.push({
                name: 'Total sugars',
                amount: data.sugarG,
                units: 'g',
                pct_daily_value: data.sugarPercentDRV ?? 0,
                items: isDefined(data.addedSugarG)
                    ? [
                          {
                              name: 'Added Sugars',
                              amount: data.addedSugarG,
                              units: 'g',
                              pct_daily_value: data.addedSugarPercentDRV ?? 0,
                          },
                      ]
                    : undefined,
            });
        }
        breakdownItems.push({
            name: 'Total Carbohydrate',
            amount: data.carbsG,
            units: 'g',
            pct_daily_value: data.carbsPercentDRV,
            items: carbItems,
        });
    }

    if (isDefined(data.proteinG) && isDefined(data.proteinPercentRDI)) {
        breakdownItems.push({
            name: 'Protein',
            amount: data.proteinG,
            units: 'g',
            pct_daily_value: data.proteinPercentRDI,
        });
    }

    return {
        breakdown: [...breakdownItems],
        calories_per_serving: data.caloriesKCal || null,
        servings_per_container: recipe.servingsPerContainer || null,
        serving_size: recipe.servingSizeUnit?.name,
        nutritionals_quantity: recipe.servingSizeQuantity,
    };
}

const Row = ({ item, depth = 0 }: { item: NutritionalItemWithItems; depth?: number }) => {
    return (
        <>
            <tr className={'text-sm'}>
                <td>
                    <span
                        className={cn('mr-2', { 'font-bold': depth === 0, 'ml-4': depth === 1, 'ml-8': depth === 2 })}
                    >
                        {item.name}
                    </span>
                    <span>
                        {Math.round(item.amount)}
                        {item.units}
                    </span>
                </td>
                <td align={'right'}>
                    {item.pct_daily_value && item.pct_daily_value > 0 ? (
                        <span className={'font-bold'}>{Math.round(item.pct_daily_value * 100)}%</span>
                    ) : null}
                </td>
            </tr>
            {item.items?.map((subItem, i) => <Row item={subItem} depth={depth + 1} key={`sub_${i}`} />)}
        </>
    );
};

export function NutritionalFactsTable({ facts }: NutritionalFactsTableProps) {
    const hasServingSize = isDefined(facts.servings_per_container) || isDefined(facts.serving_size);
    return (
        <div className={'rounded-md border p-2'}>
            {isDefined(facts.servings_per_container) && (
                <p>{pluralize(facts.servings_per_container, 'servings', 'serving')} per container</p>
            )}

            {isDefined(facts.serving_size) && (
                <div className={'flex justify-between font-bold'}>
                    <span>Serving size</span>
                    <span>
                        <span>{new Fraction(facts.nutritionals_quantity ?? 1).simplify().toFraction()} </span>
                        {facts.serving_size}
                    </span>
                </div>
            )}
            {hasServingSize && <Separator className={'my-2 h-2 bg-foreground'} />}
            <div>
                <p>Amount per serving</p>
                <div className={'flex justify-between text-lg font-bold'}>
                    <span>Calories</span>
                    <span>{facts.calories_per_serving ? Math.round(facts.calories_per_serving) : ''}</span>
                </div>
            </div>
            <Separator className={'my-2 h-1 bg-foreground'} />
            <table className={'table w-full'}>
                <thead>
                    <tr>
                        <th />
                        <th align={'right'} className={'text-xs'}>
                            % Daily Value<span className={'-top-1'}>*</span>
                        </th>
                    </tr>
                </thead>
                <tbody className={'divide-y'}>
                    {facts.breakdown.map((fact, i) => (
                        <Row item={fact} key={i} />
                    ))}
                </tbody>
            </table>
            <Separator className={'my-2 h-1 bg-foreground'} />
            <div className={'px-2 text-xs'}>
                * The % Daily Value (DV) tells you how much a nutrient in a serving of food contributes to a daily diet.
                2,000 calories a day is used for general nutritional advice
            </div>
        </div>
    );
}

/**
 * Example Nutritional facts (not to be used)
 * @deprecated
 */
export const _mockFacts: NutritionalFacts = {
    calories_per_serving: 340,
    serving_size: '1/3 cup (27g)',
    servings_per_container: 5,
    breakdown: [
        {
            name: 'Total Fat',
            amount: 47,
            units: 'g',
            pct_daily_value: 0.67,
            items: [
                { name: 'Saturated Fat', amount: 19, units: 'g', pct_daily_value: 0.45 },
                { name: 'Trans Fat', amount: 8, units: 'g', pct_daily_value: 0.34 },
            ],
        },
        { name: 'Cholesterol', amount: 2, units: 'g', pct_daily_value: 0.45 },
        { name: 'Sodium', amount: 7, units: 'g', pct_daily_value: 0.18 },
        {
            name: 'Total Carbohydrate',
            amount: 3,
            units: 'g',
            pct_daily_value: 0.08,
            items: [
                { name: 'Dietary Fiber', amount: 0, units: 'g', pct_daily_value: 0.0 },
                {
                    name: 'Total sugars',
                    amount: 0,
                    units: 'g',
                    pct_daily_value: 0.0,
                    items: [{ name: 'Added Sugars', amount: 0, units: 'g', pct_daily_value: 0.0 }],
                },
            ],
        },
        { name: 'Protein', amount: 22, units: 'g', pct_daily_value: 0.47 },
    ],
};

NutritionalFactsTable.displayName = 'NutritionalFactsTable';
