import TagCategory, { TagCategoryName } from '@/models/TagCategory';

export default interface Tag {
    id: number;
    display_name: string;
    category?: TagCategory | null;
    tag_category_id?: number | null;
}

export const isTagInCategory = (tag: Tag | null | undefined, name: TagCategoryName): boolean => {
    const equal = tag?.category?.display_name.toLowerCase() === name.toLowerCase();
    return equal;
};

export const tagHasAnyCategory = (tag: Tag | null | undefined, categories: TagCategoryName[]): boolean => {
    return categories.some((category) => isTagInCategory(tag, category));
};
