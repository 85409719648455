'use client';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import Logger from '@/util/Logger';
import Config from '@/config/Config';
import useAuth from '@/hooks/useAuth';
import * as Sentry from '@sentry/nextjs';

const logger = Logger.make('ZendeskWidget');
const zendeskKey = Config.ZENDESK.API_KEY;
declare global {
    interface Window {
        zE?: (...args: unknown[]) => void;
        zEInit?: () => void;
        zESettings?: Record<string, unknown>;
    }
}

const canUseDOM = !(typeof window === 'undefined' || !window.document || !window.document.createElement);

export const zendeskApi = (...args: unknown[]): void => {
    try {
        if (canUseDOM && window.zE) {
            window.zE.apply(null, args);
        } else {
            console.warn('Zendesk has not been initialized yet!');
        }
    } catch (error) {
        Sentry.captureException(error);
    }
};

interface ZendeskProps {
    defer?: boolean;
    disabled?: boolean;
    zendeskSettings?: Record<string, unknown>;
}

export const ZendeskWidget: FC<ZendeskProps> = ({ defer, disabled, zendeskSettings = {} }) => {
    const scriptRef = useRef({ added: false });
    const { subscriber } = useAuth();
    const [zendeskLoaded, setZendeskLoaded] = useState(false);
    const onLoadedCallback = useCallback(() => {
        console.log('ZENDESK WIDGET LOADED');
        setZendeskLoaded(true);
    }, []);

    useEffect(() => {
        if (!zendeskLoaded || !subscriber) {
            return;
        }
        console.log(`Zendesk: identifying user: ${subscriber.email}`);
        zendeskApi('webWidget', 'identify', { email: subscriber.email, name: subscriber.name });
        zendeskApi('webWidget', 'prefill', {
            email: { value: subscriber.email },
            name: { value: subscriber.name },
        });
    }, [zendeskLoaded, subscriber]);

    useEffect(() => {
        if (disabled || !zendeskKey) return;

        const onScriptLoaded = () => {
            window.zEInit?.();
        };

        const insertScript = () => {
            logger.info('adding zendesk script');
            const script = document.createElement('script');
            if (defer) {
                script.defer = true;
            } else {
                script.async = true;
            }
            script.id = 'ze-snippet';
            script.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`;
            script.addEventListener('load', onScriptLoaded);
            document.body.appendChild(script);
            scriptRef.current.added = true;
        };

        if (canUseDOM && !window.zE && !scriptRef.current.added) {
            window.zEInit = onLoadedCallback;
            const defaultSettings: ZendeskProps['zendeskSettings'] = { zIndex: 10 };
            window.zESettings = { ...defaultSettings, ...zendeskSettings };
            insertScript();
        }

        return () => {
            // if (!canUseDOM || !window.zE) return;
            // delete window.zE;
            // delete window.zEInit;
            // delete window.zESettings;
        };
    }, [defer, disabled, onLoadedCallback, zendeskKey, zendeskSettings]);

    return null;
};

export default ZendeskWidget;
