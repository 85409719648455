import React from 'react';
import { RecipeReview } from '@/models/RecipeReview';
import { faStar as SolidStar } from '@fortawesome/free-solid-svg-icons/faStar';
import { faStar as OutlineStar } from '@fortawesome/free-regular-svg-icons/faStar';
import { cn } from '@/util/styleUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateFormat, formatDateString } from '@/util/DateUtil';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';

export type RecipeReviewItemProps = {
    review: RecipeReview;
    className?: string;
    selected?: boolean;
    onEdit?: () => void;
};

const RatingStar = ({ rating, value }: { rating: number; value: number }) => {
    const selected = rating >= value;
    return (
        <FontAwesomeIcon
            icon={selected ? SolidStar : OutlineStar}
            size={'lg'}
            className={cn({ 'text-yellow-500': selected, 'text-muted-foreground/50': !selected })}
        />
    );
};

export function ReviewItem({ review, className, selected, onEdit }: RecipeReviewItemProps) {
    const rating = review.rating;
    return (
        <div
            className={cn(
                'relative space-y-2 rounded-lg border p-4',
                { ' border-2 border-primary': selected },
                className
            )}
        >
            <div className={'flex justify-between space-x-4'}>
                <div className={'flex space-x-2'}>
                    <RatingStar rating={rating} value={1} />
                    <RatingStar rating={rating} value={2} />
                    <RatingStar rating={rating} value={3} />
                    <RatingStar rating={rating} value={4} />
                    <RatingStar rating={rating} value={5} />
                </div>
                {selected && onEdit && (
                    <Button onClick={() => onEdit()} variant={'ghost'} size={'sm'} className={'absolute right-2 top-2'}>
                        Edit
                    </Button>
                )}
            </div>
            <div className={'whitespace-pre-line'}>{review.comment ?? '(no comment)'}</div>
            {review.tags && review.tags.length > 0 && (
                <div className={'flex flex-wrap gap-1'}>
                    {review.tags.map((tag) => (
                        <Badge variant={'outline'} key={`${tag}`}>
                            {tag}
                        </Badge>
                    ))}
                </div>
            )}
            <div className={'text-sm text-muted-foreground'}>
                {formatDateString(review.updated_at, DateFormat.long)}
            </div>
        </div>
    );
}

ReviewItem.displayName = 'ReviewItem';
