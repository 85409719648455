export type TimeoutValue = ReturnType<typeof setTimeout>;

export function shuffleArray<T>(array: T[]) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
}

export function toShuffledArray<T>(array: T[]) {
    const input = [...array];
    shuffleArray(input);
    return input;
}
