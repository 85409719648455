import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import { RecipeReviewDto } from '@/models/RecipeReview';
import { Badge } from '@/components/ui/badge';
import { toShuffledArray } from '@/util/ObjectUtil';

export type RecipeReviewTagsFieldProps = { name: string; onTagsChanged?: (tags: string[]) => void };

const tags_by_rating = [
    ['Not so tasty', 'Not worth the price', 'Took a long time to prep', 'Not fresh'],
    ['Not so tasty', 'Not worth the price', 'Took a long time to prep', 'Not fresh'],
    ['Not so tasty', 'Not worth the price', 'Took a long time to prep', 'Not fresh'],
    ['High-quality ingredients', 'Tasty', 'Good portion', 'Fresh', 'Quick'],
    ['High-quality ingredients', 'Tasty', 'Good portion', 'Fresh', 'Quick'],
];

function getShuffledRatingTags() {
    return [
        toShuffledArray(tags_by_rating[0]),
        toShuffledArray(tags_by_rating[1]),
        toShuffledArray(tags_by_rating[2]),
        toShuffledArray(tags_by_rating[3]),
        toShuffledArray(tags_by_rating[4]),
    ];
}

export function RecipeReviewTagsField({ name, onTagsChanged }: RecipeReviewTagsFieldProps) {
    const [shuffledByRating] = useState(getShuffledRatingTags());
    const { values, setFieldValue } = useFormikContext<Partial<RecipeReviewDto>>();
    const rating = values.rating ?? 1;
    const selected_tags = values.tags ?? [];
    const availableTags = shuffledByRating[rating - 1];

    async function toggleTag(value: string) {
        let updated_tags = [...selected_tags];
        if (selected_tags.includes(value)) {
            updated_tags = updated_tags.filter((t) => t !== value);
        } else {
            updated_tags.push(value);
        }

        await setFieldValue(name, updated_tags);
        onTagsChanged?.(updated_tags);
    }

    return (
        <div className={'flex flex-wrap justify-center gap-x-2 gap-y-1'}>
            {availableTags.map((tag) => {
                const selected = selected_tags.includes(tag);
                return (
                    <Badge
                        key={tag}
                        variant={selected ? 'default' : 'secondary'}
                        onClick={() => toggleTag(tag)}
                        className={'cursor-pointer'}
                    >
                        {tag}
                    </Badge>
                );
            })}
        </div>
    );
}

RecipeReviewTagsField.displayName = 'RecipeReviewTagsField';
