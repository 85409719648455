import React from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { RecipeOverviewContent } from '@/app/menus/[menuId]/components/recipe-card/tabs/content/RecipeOverviewContent';
import { RecipeWithPricing } from '@/models/Recipe';
import { IngredientsContent } from '@/app/menus/[menuId]/components/recipe-card/tabs/content/IngredientsContent';
import { RecipeReviewContents } from '@/app/menus/[menuId]/components/recipe-card/tabs/content/RecipeReviewContents';
import { AppleIcon, BookOpenText, CookingPotIcon, ListIcon, StarIcon } from 'lucide-react';
import { ProseContent } from '@/components/ProseContent';
import { NutritionalContents } from '@/app/menus/[menuId]/components/recipe-card/tabs/content/NutritionalContents';
import { RecipeReview } from '@/models/RecipeReview';

export type RecipeDetailTabsProps = { recipe: RecipeWithPricing; review?: RecipeReview | null; hideOverview?: boolean };

export function RecipeDetailTabs({ recipe, review, hideOverview = false }: RecipeDetailTabsProps) {
    const hasIngredients = recipe.ingredients && recipe.ingredients.length > 0;
    const hasNutritionalInfo = recipe.nutritional_info_enabled;
    const hasInstructions = recipe.cooking_instructions_enabled && recipe.cooking_instructions_html;

    let initialTab = 'overview';
    if (hideOverview) {
        if (hasInstructions) {
            initialTab = 'instructions';
        } else if (hasNutritionalInfo) {
            initialTab = 'nutritional';
        } else if (hasIngredients) {
            initialTab = 'ingredients';
        } else {
            initialTab = 'reviews';
        }
    }

    return (
        <Tabs defaultValue={initialTab}>
            <div className={'overflow-auto'}>
                <TabsList variant={'pill'} className={'px-6'}>
                    {!hideOverview && (
                        <TabsTrigger variant={'pill'} value={'overview'} className={'space-x-2'}>
                            <BookOpenText className={'h-4 w-4'} />

                            <span>Overview</span>
                        </TabsTrigger>
                    )}
                    {hasIngredients && (
                        <TabsTrigger variant={'pill'} value={'ingredients'} className={'space-x-2'}>
                            <ListIcon className={'h-4 w-4'} />
                            <span>Ingredients</span>
                        </TabsTrigger>
                    )}
                    {hasNutritionalInfo && (
                        <TabsTrigger variant={'pill'} value={'nutritional'} className={'space-x-2'}>
                            <AppleIcon className={'h-4 w-4'} />
                            <span>Nutrition Facts</span>
                        </TabsTrigger>
                    )}
                    {recipe.cooking_instructions_enabled && recipe.cooking_instructions_html && (
                        <TabsTrigger value={'instructions'} variant={'pill'} className={'space-x-2'}>
                            <CookingPotIcon className={'h-4 w-4'} />
                            <span>Cooking instructions</span>
                        </TabsTrigger>
                    )}
                    <TabsTrigger variant={'pill'} value={'reviews'} className={'space-x-2'}>
                        <StarIcon className={'h-4 w-4'} />
                        <span>Reviews</span>
                    </TabsTrigger>
                </TabsList>
            </div>
            <div className={'min-h-[75px] px-6'}>
                <TabsContent value={'overview'}>
                    <RecipeOverviewContent recipe={recipe} />
                </TabsContent>
                <TabsContent value={'ingredients'} className={'pb-6'}>
                    <IngredientsContent recipe={recipe} />
                </TabsContent>
                {recipe.nutritional_info_enabled && (
                    <TabsContent value={'nutritional'} className={'pb-6'}>
                        <NutritionalContents recipe={recipe} />
                    </TabsContent>
                )}
                <TabsContent value={'reviews'} className={'pb-6'}>
                    <RecipeReviewContents recipe={recipe} review={review} />
                </TabsContent>
                <TabsContent value={'instructions'} className={'pb-6'}>
                    <h3 className={'text-lg font-bold'}>Cooking instructions</h3>
                    {recipe.cooking_instructions_enabled && recipe.cooking_instructions_html && (
                        <ProseContent html={recipe.cooking_instructions_html} />
                    )}
                </TabsContent>
            </div>
        </Tabs>
    );
}

RecipeDetailTabs.displayName = 'RecipeDetailTabs';
