import Tag, { isTagInCategory } from '@/models/Tag';
import TagView, { TagColor } from '@/components/TagView';
import { TagCategoryName } from '@/models/TagCategory';

type Props = { tag: Tag; showCategory?: boolean; className?: string; onClose?: () => void };
const RecipeTag = ({ tag, showCategory, className, onClose }: Props) => {
    let color: TagColor | undefined;
    if (isTagInCategory(tag, TagCategoryName.promotional)) {
        color = 'orange';
    }

    return (
        <TagView
            title={tag.display_name}
            className={className}
            subtitle={showCategory ? tag.category?.display_name : undefined}
            onClose={onClose}
            color={color}
        />
    );
};

export default RecipeTag;
