import React, { ReactNode } from 'react';
import { Recipe } from '@/models/Recipe';
import { RecipeReview, RecipeReviewDto } from '@/models/RecipeReview';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import * as Yup from 'yup';
import TextareaField from '@/components/forms/controls/TextareaField';
import { RatingField } from '@/app/menus/[menuId]/components/recipe-card/tabs/content/RatingField';
import { Button } from '@/components/ui/button';
import { cn } from '@/util/styleUtils';
import useApi from '@/hooks/useApi';
import Endpoint from '@/config/Endpoint';
import { useToast } from '@/components/ui/use-toast';
import FieldLabel from '@/components/forms/controls/FieldLabel';
import { useCurrentUserReviews } from '@/hooks/useSubscriberReviews';
import { useSWRConfig } from 'swr';
import { RecipeReviewTagsField } from '@/app/menus/[menuId]/components/recipe-card/tabs/content/RecipeReviewTagsField';

export type CreateRecipeReviewFormProps = {
    recipe: Recipe;
    edit?: RecipeReview;
    initialRating?: number | null;
    children: ReactNode;
    className?: string;
    afterSave?: (review: RecipeReview) => void;
};

type FormValues = RecipeReviewDto;

const schema = Yup.object({
    comment: Yup.string().nullable().label('Comment'),
    rating: Yup.number().integer().label('Rating').min(1, 'Please choose a rating').max(5),
    tags: Yup.array(Yup.string()),
});

export function CreateRecipeReviewForm({
    recipe,
    edit,
    children,
    className,
    afterSave,
    initialRating = 0,
}: CreateRecipeReviewFormProps) {
    const api = useApi();
    const { toast } = useToast();
    const { mutate: mutateReviews } = useCurrentUserReviews();
    const { mutate } = useSWRConfig();
    const initialValues: FormValues = {
        comment: edit?.comment ?? '',
        rating: edit?.rating ?? initialRating ?? 0,
        tags: edit?.tags ?? [],
    };

    const handleSubmit = async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
        const review = await api.post<RecipeReview>(Endpoint.recipe(recipe.id).reviews, values);
        await mutateReviews(
            (current) => {
                const filtered = current?.filter((old) => old.recipe_id !== review.recipe_id) ?? [];
                return [...filtered, review];
            },
            { revalidate: true }
        );
        toast({
            variant: 'success',
            title: 'Thanks for your review!',
            description: `Your review of ${recipe.name} has been saved`,
        });
        await mutate(Endpoint.recipe(recipe.id).publicReviews);
        helpers.setSubmitting(false);
        afterSave?.(review);
    };

    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
            <Form className={cn('space-y-6', className)}>
                <>{children}</>
            </Form>
        </Formik>
    );
}

type FieldsProps = {};
export function Fields({}: FieldsProps) {
    const { values } = useFormikContext<FormValues>();
    return (
        <div className={'space-y-6'}>
            <div className={'space-y-2'}>
                <FieldLabel noMargin>Rating</FieldLabel>
                <RatingField name={'rating'} className={'justify-end text-left'} />
            </div>
            {values.rating > 0 && (
                <div className={'flex'}>
                    <RecipeReviewTagsField name={'tags'} />
                </div>
            )}
            <TextareaField rows={2} name={'comment'} label={'Leave us feedback on this item'} placeholder={''} />
        </div>
    );
}
type ActionsProps = { onCancel?: () => void; className?: string };
export function Actions({ onCancel, className }: ActionsProps) {
    const { resetForm, submitForm } = useFormikContext();
    return (
        <div className={cn('flex justify-end space-x-2', className)}>
            <Button
                variant={'ghost'}
                size={'sm'}
                type={'submit'}
                onClick={() => {
                    resetForm();
                    onCancel?.();
                }}
            >
                Cancel
            </Button>
            <Button variant={'outlined'} size={'sm'} type={'button'} onClick={() => submitForm()}>
                Submit review
            </Button>
        </div>
    );
}

CreateRecipeReviewForm.Fields = Fields;
CreateRecipeReviewForm.Actions = Actions;

CreateRecipeReviewForm.displayName = 'CreateRecipeReviewForm';
