import { createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { Subscriber } from '@/models/Subscriber';
import Endpoint from '@/config/Endpoint';
import useSWR from 'swr';
import { useAuth0, User } from '@auth0/auth0-react';
import { KeyedMutator } from 'swr/_internal';
import { hasAdminRole, hasSuperAdminRole } from '@/util/AuthRolesUtil';
import Logger from '@/util/Logger';
import useApi from '@/hooks/useApi';
import { identifyUser } from '@/config/Analytics';

const logger = Logger.make('AuthContext');
const AuthContext = createContext<{
    subscriber?: Subscriber | null;
    subscriberError: unknown;
    subscriberLoading: boolean;
    mutateSubscriber: KeyedMutator<Subscriber | null>;
    isLoading: boolean;
    user: User | undefined;
    isAdmin: boolean;
    isSuperAdmin: boolean;
    isLoggedIn: boolean;
} | null>(null);
export const useAuthContext = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('No auth context found.');
    }
    return context;
};

export function AuthContextProvider({ children }: { children: ReactNode }) {
    const api = useApi();
    const creatingRef = useRef(false);
    const [creatingSubscriber, setCreatingSubscriber] = useState(false);
    const { user, isLoading, isAuthenticated } = useAuth0();
    const {
        data: subscriber,
        error: subscriberError,
        isLoading: subscriberLoading,
        mutate: mutateSubscriber,
    } = useSWR<Subscriber | null>(() => (isAuthenticated && user ? Endpoint.me : null));

    const email = user?.email;
    const subscriber_id = subscriber?.id;
    const full_name = subscriber?.name;
    useEffect(() => {
        if (subscriber_id && email) {
            identifyUser({ email, id: subscriber_id, name: full_name });
        } else {
            identifyUser(null);
        }
    }, [email, subscriber_id, full_name]);

    const createSubscriber = async (user: User): Promise<Subscriber | null | undefined> => {
        if (creatingRef.current) {
            logger.info('Already in process of creating subscriber...');
            return null;
        }
        creatingRef.current = true;
        logger.info('attempting to create new subscriber');
        setCreatingSubscriber(true);
        const created = await mutateSubscriber(
            async (current) => {
                if (current) {
                    logger.info('subscriber already exits, not creating a new one');
                    return current;
                }
                try {
                    return await api.post<Subscriber>(Endpoint.subscribers, user);
                } catch (error) {
                    return current;
                }
            },
            { revalidate: false }
        );
        setCreatingSubscriber(false);
        creatingRef.current = false;
        return created;
    };

    useEffect(() => {
        if (user && !subscriber && subscriberError) {
            logger.info('auth user exists and no subscriber and subscriber has error');
            createSubscriber(user).then((result) => logger.info('Created new subscriber', result));
        }
    }, [user, subscriber, subscriberError]);

    return (
        <AuthContext.Provider
            value={{
                subscriber,
                subscriberError,
                subscriberLoading,
                mutateSubscriber,
                isLoading: isLoading || subscriberLoading || creatingSubscriber,
                user,
                isAdmin: hasAdminRole(user),
                isSuperAdmin: hasSuperAdminRole(user),
                isLoggedIn: !!subscriber,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}
